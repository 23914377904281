import React from 'react';
import { createRoot } from 'react-dom/client';
import SUCompareWidget, { COMPARE_WIDGET_ID } from 'shared-ui/src/components/organism/CompareWidget';

function CompareWidget({ quizUrl, productClassName, productSlug }) {
  return <SUCompareWidget quizUrl={quizUrl} productClassName={productClassName} productSlug={productSlug} />;
}

export function renderCompareWidget() {
  const element = document.getElementById(COMPARE_WIDGET_ID);

  if (element) {
    const productClassName = element.getAttribute('data-classname');
    const productSlug = element.getAttribute('data-slug');
    const quizUrl = element.getAttribute('data-quiz-url');

    const root = createRoot(element);

    root.render(<CompareWidget productClassName={productClassName} productSlug={productSlug} quizUrl={quizUrl} />);
  }
}

export default CompareWidget;
