import React, { FC } from 'react';
import { PageRegion } from 'datalayer-service/src/types/enums';
import { UpdateUserFn, User } from '../../../../types/user';
import { SigninMethodType } from '../../../../types/signin';
import { Deal, SaveDealItem } from '../../../../types/deal';
import SaveDealsForm, { SaveDealsFormFormikProps } from '../../../atoms/SaveDealsForm';

export type SendDealsProps = {
  retailerName: string;
  user: User | null;
  pageRegion: PageRegion;
  deal?: Deal;
  saveDealItems: SaveDealItem[];
  isOnline: boolean;
  brandName?: string;
  isMultiDeal?: boolean;
  signinMethod?: SigninMethodType; // for unit tests
  updateUser: UpdateUserFn;
  onSubmit(values: SaveDealsFormFormikProps): void;
  showFooter?: boolean;
};

export const SEND_DEALS_ID = 'promo-modal-send-deals';
export const SEND_DEALS_FOOTER_TEST_ID = 'promo-modal-send-deals-footer';
export const SEND_DEALS_TITLE = 'Save this for later?';
export const SEND_DEALS_DISCLAIMER = // eslint-disable-next-line
  'By clicking here, you agree to receive a one-time text message (SMS) from GoodBed with this information. Standard data and message rates apply.';
export const SEND_DEALS_SINGLE_DEAL_BTN_TEXT = 'Send Me This Deal';
export const SEND_DEALS_MULTI_DEALS_BTN_TEXT = 'Send Me These Deals';

export const getSendDealsSubtitle = (retailerName?: string, brandName?: string, isMultiDeal?: boolean): string => {
  const extra = isMultiDeal ? `a ${brandName}` : `from ${retailerName}`;

  return `Make sure you get this deal if you decide to buy ${extra}. No spam, guaranteed.`;
};

const SendDeals: FC<SendDealsProps> = ({
  retailerName,
  pageRegion,
  user,
  deal,
  saveDealItems,
  isOnline,
  brandName,
  isMultiDeal,
  onSubmit,
  showFooter = true,
}) => {
  return (
    <div id={SEND_DEALS_ID} data-testid={SEND_DEALS_ID} className="send-deals">
      <div className="send-deals__title">{SEND_DEALS_TITLE}</div>
      <div className="send-deals__subtitle">{getSendDealsSubtitle(retailerName, brandName, isMultiDeal)}</div>

      <SaveDealsForm
        user={user}
        isMultiDeal={isMultiDeal}
        saveDealItems={saveDealItems}
        pageRegion={pageRegion}
        deal={deal}
        onSubmit={onSubmit}
      />

      {showFooter ? (
        <div className="send-deals__footer" data-testid={SEND_DEALS_FOOTER_TEST_ID}>
          <div className="send-deals__footer-title">Your message will include:</div>
          <ol className="send-deals__footer-list">
            <li className="send-deals__footer-list-item">
              {isOnline ? (
                <>
                  <b>Your personal shopping link.</b> Start your online purchase here to be eligible for Cash Back.
                </>
              ) : (
                <>
                  <b>Your personal Cash Back link.</b> Use this link after purchase to claim your Cash Back from
                  GoodBed.
                </>
              )}
            </li>
            <li className="send-deals__footer-list-item">
              <b>Current promo codes.</b> Combine your Cash Back with the best available sales and coupons.
            </li>
            <li className="send-deals__footer-list-item">
              {isOnline ? (
                <>
                  <b>Your personal cashback link.</b> Use this link after purchase to claim your Cash Back from GoodBed.
                </>
              ) : (
                <>
                  <b>Offer details.</b> Terms, expiration dates, etc.
                </>
              )}
            </li>
          </ol>
        </div>
      ) : null}
    </div>
  );
};

export default SendDeals;
