import { ResponsiveType } from 'react-multi-carousel';

export const PRODUCTS_CAROUSEL_BREAKPOINTS: ResponsiveType = {
  xl: {
    breakpoint: { min: 1200, max: 3000 },
    items: 4,
    partialVisibilityGutter: 3,
  },
  lg: {
    breakpoint: { min: 992, max: 1200 },
    items: 3,
    partialVisibilityGutter: 5,
  },
  xMd: {
    breakpoint: { min: 768, max: 992 },
    items: 3,
    partialVisibilityGutter: 32,
  },
  md: {
    breakpoint: { min: 576, max: 768 },
    items: 2,
    partialVisibilityGutter: 30,
  },
  sm: {
    breakpoint: { min: 550, max: 576 },
    partialVisibilityGutter: 40,
    items: 3,
  },
  xSm: {
    breakpoint: { min: 515, max: 550 },
    partialVisibilityGutter: 25,
    items: 3,
  },
  xsSm: {
    breakpoint: { min: 480, max: 515 },
    partialVisibilityGutter: 15,
    items: 3,
  },
  xssSm: {
    breakpoint: { min: 440, max: 480 },
    partialVisibilityGutter: 5,
    items: 3,
  },
  xsssSm: {
    breakpoint: { min: 420, max: 440 },
    partialVisibilityGutter: 65,
    items: 2,
  },
  xssssSm: {
    breakpoint: { min: 390, max: 420 },
    partialVisibilityGutter: 50,
    items: 2,
  },
  preMini: {
    breakpoint: { min: 375, max: 390 },
    partialVisibilityGutter: 40,
    items: 2,
  },
  mini: {
    breakpoint: { min: 360, max: 375 },
    partialVisibilityGutter: 37,
    items: 2,
  },
  uMini: {
    breakpoint: { min: 0, max: 360 },
    partialVisibilityGutter: 26,
    items: 2,
  },
  usMini: {
    breakpoint: { min: 0, max: 340 },
    partialVisibilityGutter: 17,
    items: 2,
  },
  ussMini: {
    breakpoint: { min: 0, max: 330 },
    partialVisibilityGutter: 8,
    items: 2,
  },
  smallest: {
    breakpoint: { min: 0, max: 315 },
    partialVisibilityGutter: 3,
    items: 2,
  },
};
