import React, { FC } from 'react';
import cls from 'classnames';
import { PageRegion } from 'datalayer-service/src/types/enums';
import { format } from 'date-fns';
import { NewPromotion, PromotionType } from '../../../types/promotion';
import { getUrlAsDisplayText } from '../../../utils/urls';
import usePromoModalShortLinks from '../../../hooks/usePromoModalShortLinks';
import CopyPasteBox from '../../atoms/CopyPasteBox';
import ArrowDoodleIcon from '../../atoms/Icons/ArrowDoodle';
import SmallArrowDoodleIcon from '../../atoms/Icons/SmallArrowDoodle';
import PromoModalBodyPromoBoxes from './BodyPromoBoxes';
import { Deal } from '../../../types/deal';
import { parseLocalDate } from '../../../utils/dates';
import { SEND_DEALS_ID } from './SendDeals';
import RewardShoppingLinkText from './RewardShoppingLinkText';
import useBreakPoints from '../../../hooks/useBreakPoints';
import { shouldShowIncognitoInfo } from '../../../utils/promos';

type PromoModalBodyRebatePromotionProps = {
  pageRegion: PageRegion;
  deal?: Deal;
  onlinePromotion?: NewPromotion;
  rebatePromotion?: NewPromotion;
  generalPromotion?: NewPromotion;
  notListedBrand?: boolean;
  toggleable?: boolean;
  showReadyToBuy?: boolean;
  skipShortLinks?: boolean;
};

const PromoModalBodyRebatePromotion: FC<PromoModalBodyRebatePromotionProps> = ({
  pageRegion,
  deal,
  onlinePromotion,
  rebatePromotion,
  generalPromotion,
  toggleable = true,
  notListedBrand,
  showReadyToBuy = true,
  skipShortLinks = false,
}) => {
  const { isMobile } = useBreakPoints();
  const {
    isLoading: isLoadingShortLinks,
    rebateRedeemLink,
    rebateShoppingLink,
    redeemExpirationDate,
  } = usePromoModalShortLinks(rebatePromotion, onlinePromotion, skipShortLinks);

  const dateExpirationText = redeemExpirationDate
    ? format(parseLocalDate(redeemExpirationDate as string), 'MMMM d')
    : undefined;

  const retailer = deal?.deals[0].retailer;

  const getCopyPasteBox = (isCashback = false, mobile = false): JSX.Element => {
    const isRebatePromotion = rebatePromotion?.promotion_type === PromotionType.LOCAL_REBATE;
    const copyPasteText = getUrlAsDisplayText(
      isRebatePromotion ? rebateRedeemLink : rebateShoppingLink,
      /(http:\/\/|https:\/\/|www\.)/g,
    );

    return (
      <CopyPasteBox
        className={cls({ 'is-mobile': isCashback && mobile, 'is-desktop': isCashback && !mobile })}
        text={copyPasteText || ''}
        showLinkMode={shouldShowIncognitoInfo(rebatePromotion)}
        url={rebateShoppingLink}
        isLoading={isLoadingShortLinks}
      />
    );
  };

  const getReadyToBuyTitle = (): JSX.Element => {
    return (
      <b>
        Get this deal now, or <a href={`#${SEND_DEALS_ID}`}>save it for later:</a>
      </b>
    );
  };

  const getRewardDescriptionText = (mobile: boolean): JSX.Element => {
    if (rebatePromotion?.promotion_type === PromotionType.LOCAL_REBATE) {
      return (
        <>
          After purchasing from {retailer?.name}, use this personal link to claim your extra Cash Back from GoodBed.
          It’s valid for any qualifying purchase made during the next 2 weeks (purchase by {dateExpirationText}).
        </>
      );
    }

    if (shouldShowIncognitoInfo(rebatePromotion)) {
      return (
        <>
          When you&apos;re ready to buy, click the link {mobile ? 'above' : 'below'} — this will open the{' '}
          {retailer?.name} website in a manner that lets them know you came from GoodBed.
        </>
      );
    }

    return <RewardShoppingLinkText retailerName={retailer?.name || ''} />;
  };

  return (
    <div className="shared-ui__promo-modal__body-cashback">
      {onlinePromotion && rebatePromotion && (
        <div className="shared-ui__promo-modal__body-cashback-combine-both">
          Combine both offers.
          <ArrowDoodleIcon />
          <SmallArrowDoodleIcon />
        </div>
      )}
      <PromoModalBodyPromoBoxes
        pageRegion={pageRegion}
        dealItem={deal?.deals[0]}
        onlinePromotion={onlinePromotion}
        rebatePromotion={rebatePromotion}
        generalPromotion={generalPromotion}
        rebateRedeemLink={rebateRedeemLink}
        isLoadingShortLinks={isLoadingShortLinks}
        notListedBrand={notListedBrand}
        toggleable={toggleable}
      />
      {showReadyToBuy && (
        <div className="shared-ui__promo-modal__body-cashback-ready-to-buy">
          <div className="ready-to-buy-title">{getReadyToBuyTitle()}</div>
          {getCopyPasteBox(true, true)}
          <p className="ready-to-buy-description">{getRewardDescriptionText(isMobile)}</p>
          {getCopyPasteBox(true)}
        </div>
      )}
    </div>
  );
};

export default PromoModalBodyRebatePromotion;
