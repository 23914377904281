import React from 'react';
import { createRoot } from 'react-dom/client';
import PropTypes from 'prop-types';
import useFetchPromoProduct from 'shared-ui/src/hooks/useFetchPromoProduct';
import SUProductDiscountBox, {
  VERTICAL,
  HORIZONTAL,
  PRODUCT_DISCOUNT_BOX_ID,
} from 'shared-ui/src/components/molecules/ProductDiscountBox';
import { MANUFACTURER, LINE, MATTRESS_COLLECTION, MATTRESS } from 'shared-ui/src/constants/commons';

function ProductDiscountBox({
  productSlug,
  productClassname,
  pageRegion,
  onGetDeal,
  layout = VERTICAL,
  posthogTesting = false,
}) {
  const { hasRequestFinished, product } = useFetchPromoProduct(productClassname, productSlug);

  return hasRequestFinished ? (
    <SUProductDiscountBox
      userData={window.userData}
      pageRegion={pageRegion}
      product={product}
      layout={layout || VERTICAL}
      posthogTesting={posthogTesting}
      onGetDeal={onGetDeal}
    />
  ) : null;
}

ProductDiscountBox.propTypes = {
  productSlug: PropTypes.string.isRequired,
  productClassname: PropTypes.oneOf([MANUFACTURER, LINE, MATTRESS_COLLECTION, MATTRESS]).isRequired,
  pageRegion: PropTypes.string.isRequired,
  posthogTesting: PropTypes.bool,
  layout: PropTypes.oneOf([VERTICAL, HORIZONTAL]),
};

export default ProductDiscountBox;

export function renderProductDiscountBox(onGetDeal, selector = `[id=${PRODUCT_DISCOUNT_BOX_ID}]`) {
  const elements = document.querySelectorAll(selector);

  elements.forEach((element) => {
    const productSlug = element.getAttribute('data-product-slug');
    const productClassname = element.getAttribute('data-product-classname');
    const pageRegion = element.getAttribute('data-page-region');
    const layout = element.getAttribute('data-layout');
    const posthogTesting = element.getAttribute('data-posthog-testing');

    const root = createRoot(element);

    root.render(
      <ProductDiscountBox
        productSlug={productSlug}
        productClassname={productClassname}
        pageRegion={pageRegion}
        layout={layout}
        posthogTesting={posthogTesting === 'true'}
        onGetDeal={onGetDeal}
      />,
    );
  });
}
