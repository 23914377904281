import React, { FC } from 'react';
import cls from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus';
import { PageRegion } from 'datalayer-service/src/types/enums';
import { NewPromotion } from '../../../types/promotion';
import { DealItem } from '../../../types/deal';
import PromoExpandBox from '../PromoExpandBox';
import { Brand } from '../../../types/brands';
import { getSeasonDiscountText, shouldRequiredPromoCode } from '../../../utils/promos';
import PromoBox from '../../atoms/PromoBox';

export const PROMO_MODAL_BODY_PROMO_BOXES_DATA_TEST_ID = 'promo-modal-body-promo-boxes';

type PromoModalBodyPromoBoxesProps = {
  pageRegion: PageRegion;
  dealItem?: DealItem;
  onlinePromotion?: NewPromotion;
  rebatePromotion?: NewPromotion;
  generalPromotion?: NewPromotion;
  notListedBrand?: boolean;
  rebateRedeemLink?: string;
  isLoadingShortLinks?: boolean;
  toggleable?: boolean;
};

const PromoModalBodyPromoBoxes: FC<PromoModalBodyPromoBoxesProps> = ({
  pageRegion,
  dealItem,
  onlinePromotion,
  rebatePromotion,
  generalPromotion,
  rebateRedeemLink,
  notListedBrand,
  isLoadingShortLinks = false,
  toggleable = true,
}) => {
  const isOnlyPromotion = (): boolean => {
    return !!(
      (rebatePromotion && !onlinePromotion && !generalPromotion) ||
      (!rebatePromotion && onlinePromotion && !generalPromotion) ||
      (!rebatePromotion && !onlinePromotion && generalPromotion)
    );
  };

  const showPlaceholderRetailerPromo = notListedBrand && rebatePromotion && isOnlyPromotion();

  return (
    <div
      data-testid={PROMO_MODAL_BODY_PROMO_BOXES_DATA_TEST_ID}
      className={cls('shared-ui__promo-modal__body-promo-boxes-container', {
        'is-only-promotion': isOnlyPromotion() && !notListedBrand,
        'not-listed': notListedBrand,
      })}
    >
      {showPlaceholderRetailerPromo ? (
        <>
          <PromoBox
            imageSrc={dealItem?.retailer.logo}
            title={getSeasonDiscountText(new Date()).toUpperCase()}
            subTitle="with best available discounts store-wide"
            variant="dashed"
          />
          <div className="plus-text">
            <FontAwesomeIcon icon={faPlus} />
            <br />
            <span>Plus</span>
          </div>
          <PromoBox
            title={rebatePromotion.title_short}
            subTitle={rebatePromotion.reward_description}
            ribbonVariant="extra"
          />
        </>
      ) : null}

      {onlinePromotion || generalPromotion ? (
        <>
          <PromoExpandBox
            product={dealItem?.brand as Brand}
            promotion={(onlinePromotion || generalPromotion) as NewPromotion}
            retailer={dealItem?.retailer}
            pageRegion={pageRegion}
            toggleable={toggleable}
            requiredPromoCode={shouldRequiredPromoCode(rebatePromotion)}
          />
          {rebatePromotion ? (
            <div className="plus-text">
              <FontAwesomeIcon icon={faPlus} />
              <br />
              <span>Plus</span>
            </div>
          ) : null}
        </>
      ) : null}

      {rebatePromotion && !showPlaceholderRetailerPromo ? (
        <PromoExpandBox
          product={dealItem?.brand as Brand}
          promotion={rebatePromotion}
          retailer={dealItem?.retailer}
          pageRegion={pageRegion}
          redeemLink={rebateRedeemLink}
          isLoadingShortLinks={isLoadingShortLinks}
          toggleable={toggleable}
        />
      ) : null}
    </div>
  );
};

export default PromoModalBodyPromoBoxes;
