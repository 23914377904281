'use client';

import React, { FC, useEffect, useRef } from 'react';
import cls from 'classnames';
import { CTAClickCategory, CTAClickLevel, PageRegion } from 'datalayer-service/src/types/enums';
import SmoothChevronIcon from '../../../atoms/Icons/SmoothChevron';
import styles from './ProductsToCompare.module.scss';
import { CompareItem } from '../../../../types/mattress-comparison';
import { ExtraProductCompareItem } from '../CompareItem';
import useCollapse from '../../../../hooks/useCollapse';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import LinkButton from '../../../atoms/LinkButton';
import { MATTRESS_HIERARCHY_CLASSNAMES, ROUTES } from '../../../../constants/commons';
import useCompareWidgetState from '../store';
import useFetch from '../../../../hooks/useFetch';
import { mapToCompareItem } from '../../../../utils/mattress-comparison';
import MultiCarousel from '../../../molecules/MultiCarousel';
import { PRODUCTS_CAROUSEL_BREAKPOINTS } from '../constants';
import compareWidgetStyles from '../CompareWidget.module.scss';

interface CollapsedListProps {
  products: CompareItem[];
  haveProductsLoaded: boolean;
  title: string;
  quizUrl?: string;
  modelsFromQuizResults?: boolean;
  shouldBeCollapsed?: boolean;
}

const CollapsedList: FC<CollapsedListProps> = ({
  products,
  haveProductsLoaded,
  title,
  modelsFromQuizResults,
  quizUrl,
  shouldBeCollapsed = false,
}) => {
  const listContainerRef = useRef<HTMLDivElement>(null);
  const isMobile = useMediaQuery('(max-width: 576px)');

  const { toggleCollapse, isCollapsed, isCollapsing, recalculateHeight } = useCollapse(listContainerRef, {
    initialCollapse: shouldBeCollapsed,
  });

  useEffect(() => {
    toggleCollapse(shouldBeCollapsed);
  }, [shouldBeCollapsed]);

  useEffect(() => {
    if (isMobile) toggleCollapse(true);
  }, []);

  useEffect(() => {
    recalculateHeight();
  }, [haveProductsLoaded]);

  const getCollapsedElement = (): JSX.Element => {
    // Case when quiz was not taken.
    if (modelsFromQuizResults && products.length === 0)
      return (
        <div className={styles.quizCollapseElement}>
          <LinkButton
            to={quizUrl || ROUTES.mattressFinderQuiz}
            className={styles.quizCTA}
            ariaLabel="Take the GoodBed Match Quiz"
            variant="quiz-outline"
            target="_blank"
            ctaData={{
              pageRegion: PageRegion.COMPARE_WIDGET,
              category: CTAClickCategory.TAKE_THE_QUIZ,
              level: CTAClickLevel.PRIMARY,
            }}
          >
            <b>Take the GoodBed Quiz</b>
            <p>to compare vs. your best matches</p>
          </LinkButton>
        </div>
      );

    return isMobile ? (
      <ul className={cls(compareWidgetStyles.compareWidgetList, styles.productList)}>
        {products.map((product) => (
          <ExtraProductCompareItem key={`${product.slug}-compare-item`} compareItem={product} />
        ))}
      </ul>
    ) : (
      <MultiCarousel className={styles.productsCarousel} responsive={PRODUCTS_CAROUSEL_BREAKPOINTS}>
        {products.map((product) => (
          <ExtraProductCompareItem key={`${product.slug}-compare-item`} compareItem={product} />
        ))}
      </MultiCarousel>
    );
  };

  return (
    <li className={cls(styles.compareWidgetExtraProductsItem, { collapsed: isCollapsed && !isCollapsing })}>
      <button
        type="button"
        className={styles.accordionTrigger}
        onClick={() => {
          toggleCollapse();
        }}
      >
        {title}
        <SmoothChevronIcon rotate={isCollapsed ? 270 : 90} />
      </button>

      <div className={styles.collapsedElementContainer} ref={listContainerRef}>
        {getCollapsedElement()}
      </div>
    </li>
  );
};

interface ProductsToCompareProps {
  className?: string;
  quizUrl?: string; // Quiz Url with quizpath can be provided by Django template in the case of profile pages.
  shouldBeCollapsed?: boolean;
  compareClassname?: keyof typeof MATTRESS_HIERARCHY_CLASSNAMES;
}

const ProductsToCompare: FC<ProductsToCompareProps> = ({
  quizUrl,
  className = '',
  compareClassname,
  shouldBeCollapsed = false,
}) => {
  const titleClassName = compareClassname === 'manufacturer' ? 'Product lines' : 'Mattresses';
  const quizListTitle =
    compareClassname === 'manufacturer'
      ? `${titleClassName} from your Quiz Results`
      : `${titleClassName} from your Quiz Results`;

  const { compareType } = useCompareWidgetState();

  const {
    data: popularProducts,
    isLoading: popularProductsLoading,
    error: popularProductsError,
  } = useFetch<any[]>('/mattress-comparison/popular-products/', { type: compareType });

  const {
    data: quizComparisonData,
    isLoading: quizComparisonDataLoading,
    error: quizComparisonDataError,
  } = useFetch<string | CompareItem[]>('/mattress-comparison/quiz-result/', {
    type: compareType === 'mod' ? 'mod' : 'brand',
  });

  const comparePopularProducts = popularProducts ? popularProducts.map((product) => mapToCompareItem(product)) : [];
  const compareQuizProducts =
    quizComparisonData && Array.isArray(quizComparisonData)
      ? quizComparisonData.map((product) => mapToCompareItem(product))
      : [];

  const hidePopularProductsSection =
    ((!comparePopularProducts || !comparePopularProducts.length) && !popularProductsLoading) || popularProductsError;

  const hideQuizCompareSection = (!quizComparisonData?.length && !quizComparisonDataLoading) || quizComparisonDataError;

  return (
    <div className={cls(styles.compareWidgetExtraProducts, className)}>
      <div className={styles.compareWidgetExtraProductsContainer}>
        {hidePopularProductsSection ? null : (
          <CollapsedList
            haveProductsLoaded={popularProductsLoading}
            products={comparePopularProducts}
            title={`Popular ${titleClassName.toLowerCase()}`}
          />
        )}
        {hideQuizCompareSection ? null : (
          <CollapsedList
            products={compareQuizProducts}
            haveProductsLoaded={quizComparisonDataLoading}
            title={quizListTitle}
            quizUrl={quizUrl}
            modelsFromQuizResults
            shouldBeCollapsed={shouldBeCollapsed}
          />
        )}
      </div>
    </div>
  );
};

export default ProductsToCompare;
