'use client';

import React, { FC, ReactNode, useEffect, useState } from 'react';
import { CTAClickCategory, CTAClickLevel, PageRegion } from 'datalayer-service/src/types/enums';
import cls from 'classnames';
import AsyncSelect from '../../molecules/AsyncSelect';
import { CompareItem as ICompareItem, MAX_COMPARE_ITEMS } from '../../../types/mattress-comparison';
import useMediaQuery from '../../../hooks/useMediaQuery';
import {
  formatComparisonSearchOptions,
  getCompareItemType,
  getCompareURL,
  mapToCompareItem,
} from '../../../utils/mattress-comparison';
import { OnChangeParams } from '../../../types/fields-definitions';
import LinkButton from '../../atoms/LinkButton';
import CompareItem, { EmptyCompareItem } from './CompareItem';
import { MATTRESS_HIERARCHY_CLASSNAMES } from '../../../constants/commons';
import styles from './CompareWidget.module.scss';
import SmoothChevronIcon from '../../atoms/Icons/SmoothChevron';
import Modal from '../../atoms/Modal';
import ProductsToCompare from './ProductsToCompare';
import { SEARCH_COMPARE_URL } from '../../../constants/mattress-compare';
import useCompareWidgetState from './store';
import fetch from '../../../utils/fetchService';

export const COMPARE_WIDGET_ID = 'compare-widget';
export const COMPARE_ITEMS_DESKTOP = 4;
export const COMPARE_ITEMS_MOBILE = 3;

type ProductClassName = keyof typeof MATTRESS_HIERARCHY_CLASSNAMES;

interface CompareWidgetProps {
  productClassName?: ProductClassName;
  productSlug?: string;
  quizUrl?: string; // Quiz Url with quizpath can be provided by Django template in the case of profile pages.
}

const CompareWidget: FC<CompareWidgetProps> = ({ productClassName = 'manufacturer', productSlug, quizUrl }) => {
  const [showModal, setShowModal] = useState(false);
  const [inputValue, setInputValue] = useState<string>('');
  const [searchableData, setSearchableData] = useState<any[]>([]);

  const { compareItems, setCompareItems, setCompareType } = useCompareWidgetState();

  useEffect(() => {
    if (productClassName && productSlug)
      fetch
        .get<any[]>('/mattress-comparison/prepopulated/', {
          type: productClassName,
          slug: productSlug,
        })
        .then((response) => {
          if (response && response.length) {
            const newCompareItems = response.map((product) => mapToCompareItem(product));
            setCompareItems(newCompareItems);
          }
        });
    setCompareType(getCompareItemType(productClassName));
  }, []);

  const isMobile = useMediaQuery('(max-width: 576px)');

  const hasMaxItems = compareItems.length >= MAX_COMPARE_ITEMS;

  const handleOptionsFormatter = (data: any[]): { label: JSX.Element | string; value: string }[] => {
    setSearchableData(data);

    return formatComparisonSearchOptions(data, compareItems);
  };

  const handleSelectItem = (item: ICompareItem, selected: boolean): void => {
    let newItems: ICompareItem[];
    if (selected) {
      newItems = compareItems.concat(item);
    } else {
      newItems = compareItems.filter((c) => c.slug !== item.slug);
    }
    setCompareItems(newItems);
  };

  const handleAsyncSelected = ({ target }: OnChangeParams): void => {
    const selectedItem = searchableData.find((item) => item.slug === target.value);
    if (selectedItem) {
      handleSelectItem(mapToCompareItem(selectedItem), true);
    }
  };

  const getEmptyCompareItems = (): ReactNode => {
    if (compareItems.length >= MAX_COMPARE_ITEMS) return null;

    let emptyCompareItemClassname = 'mattress';

    if (productClassName === 'manufacturer') emptyCompareItemClassname = 'product line';

    const emptyCompareItemText = isMobile
      ? `Add another ${emptyCompareItemClassname} to compare`
      : `Select a ${emptyCompareItemClassname} below to add it to your comparison`;

    const itemsCount = (isMobile ? COMPARE_ITEMS_MOBILE : COMPARE_ITEMS_DESKTOP) - compareItems.length;
    const result: ReactNode[] = [];

    for (let i = 0; i < itemsCount; i++) {
      result.push(
        <EmptyCompareItem
          key={`empty-compare-item-${i}`}
          text={emptyCompareItemText}
          onClick={
            isMobile
              ? (): void => {
                  setShowModal(true);
                }
              : undefined
          }
        />,
      );
    }

    return result;
  };

  const getWidgetBody = (isModal = false): JSX.Element => {
    const titleClassname = compareItems[0]?.classname !== 'mattress' ? 'product lines' : 'products';

    return (
      <div className={styles.compareWidgetBody}>
        <div className={styles.compareWidgetMainSection}>
          <p className={styles.compareWidgetSubtitle}>Select up to 5 {titleClassname} to compare.</p>
          <div className={styles.compareWidgetListContainer}>
            <ul className={styles.compareWidgetList}>
              {compareItems.map((item) => (
                <CompareItem key={`${item.slug}-compare-selected-list-item`} compareItem={item} />
              ))}
              {getEmptyCompareItems()}
            </ul>
          </div>

          <div className={styles.compareWidgetAddSection}>
            {!isMobile || isModal ? (
              <>
                <p className={styles.compareWidgetSubtitle}>Add by Model Name</p>
                <p className={styles.compareWidgetDescription}>
                  Enter part of the model name. Select an option from the drop-down menu to add it to your comparison.
                </p>
                <AsyncSelect
                  wrapperClassname={styles.searchFieldWrapper}
                  className={styles.compareWidgetSearchInput}
                  placeholder={hasMaxItems ? "You've maxed out!" : 'eg, ProAdapt Soft'}
                  name="search_mattress"
                  url={SEARCH_COMPARE_URL}
                  query={{ q: inputValue, type: compareItems[0]?.type || '' }}
                  inputValue={inputValue}
                  apiVersion={2}
                  pageRegion={PageRegion.COMPARE_WIDGET}
                  isDisabled={hasMaxItems}
                  clearable
                  searchIcon
                  closeMenuOnSelect
                  shouldResetOnSelect
                  optionsFormatter={handleOptionsFormatter}
                  onInputChange={(newValue): void => {
                    setInputValue(newValue);
                  }}
                  onChange={handleAsyncSelected}
                />{' '}
              </>
            ) : null}

            <LinkButton
              className={styles.compareWidgetButton}
              ariaLabel="Go to Compare"
              size="lg"
              disabled={compareItems.length < 2}
              useRouter={false}
              to={getCompareURL(compareItems)}
              target="_blank"
              ctaData={{
                category: CTAClickCategory.COMPARE,
                level: CTAClickLevel.PRIMARY,
                url: '',
                pageRegion: PageRegion.COMPARE,
              }}
            >
              Go to Compare <SmoothChevronIcon className={styles.compareWidgetButtonIcon} rotate={180} />
              <SmoothChevronIcon className={styles.compareWidgetButtonIcon} rotate={180} />
            </LinkButton>
          </div>
        </div>
        <ProductsToCompare
          className={styles.compareWidgetExtraProductsSection}
          quizUrl={quizUrl}
          shouldBeCollapsed={isMobile && !isModal}
          compareClassname={productClassName as ProductClassName}
        />
      </div>
    );
  };

  return (
    <div className={cls(styles.compareWidget, styles.compareWidgetInline)}>
      {getWidgetBody()}

      <Modal
        show={showModal}
        title="Add to Compare"
        className={cls(styles.compareWidgetModal, styles.compareWidget)}
        onHide={() => {
          setShowModal(false);
        }}
        body={getWidgetBody(true)}
      />
    </div>
  );
};

export default CompareWidget;
