import React, { memo } from 'react';
import cls from 'classnames';
import DataLayerService from 'datalayer-service';
import { CTAClick } from 'datalayer-service/src/types';
import { CODE_ENDING } from '../../../constants/commons';
import { openProductDiscountOutboundUrl } from '../../../utils/promos';

type PromoButtonProps = {
  className?: string;
  textClassname?: string;
  codeClassname?: string;
  layerClassname?: string;
  imgClassname?: string;
  // id, classname and slug are required if isClaimable = false
  id?: number;
  classname?: string;
  slug?: string;
  outboundURL?: string;
  isClaimable?: boolean;
  variant?: 'red' | 'blue';
  ctaData: CTAClick;
  onClick?: () => void;
};

const PromoButton: React.FunctionComponent<PromoButtonProps> = ({
  className,
  textClassname = '',
  codeClassname = '',
  layerClassname = '',
  imgClassname = '',
  id,
  classname,
  slug,
  outboundURL,
  isClaimable = false,
  variant = 'blue',
  ctaData,
  onClick,
}: PromoButtonProps) => {
  const handleClick = (): void => {
    const dataLayerService = new DataLayerService(window);

    dataLayerService.CTAClickEvent(ctaData);

    if (onClick) onClick();
    else if (!isClaimable && id && classname && slug) openProductDiscountOutboundUrl(id, classname, slug, outboundURL);
  };

  return (
    <>
      {/* eslint-disable-next-line */}
      <a className={cls('btn-promotion', className, `${variant}-btn`)} rel="nofollow" onClick={handleClick}>
        <div>
          <div className={`btn-promotion-text ${textClassname}`}>
            <span>{isClaimable ? 'get my code' : 'get deal'}</span>
          </div>
          <div className={`btn-promotion-code ${codeClassname}`}>{CODE_ENDING}</div>
          <span className={`btn-promotion-layer ${layerClassname}`} />
          <span className={`btn-promotion-image ${imgClassname}`} />
        </div>
      </a>
    </>
  );
};

export default memo(PromoButton);
