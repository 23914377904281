import { User } from '../types/user';

class PostHogService {
  posthog: any | undefined;

  user: User | null;

  constructor(user: User) {
    if (typeof window !== 'undefined' && window.posthog) this.posthog = window.posthog;
    this.user = user;
  }

  getFeatureFlag(flag: string): string {
    return this.posthog?.getFeatureFlag(flag);
  }

  capture(experimentsGoal: string): boolean {
    if (this.user?.profile?.test_account) return false;

    return this.posthog?.capture(experimentsGoal);
  }
}

export default PostHogService;
