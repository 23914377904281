import React, { FC } from 'react';
import Row from 'react-bootstrap/Row';
import cls from 'classnames';
import { EMPTY_EVENT_PRODUCT } from 'datalayer-service/src/event-objects';
import { CTAClickCategory, CTAClickLevel, PageRegion } from 'datalayer-service/src/types/enums';
import { Brand } from '../../../types/brands';
import { Deal } from '../../../types/deal';
import { User } from '../../../types/user';
import {
  getIsLocalRebatePromotionFromSingleDeal,
  getPromotionsFromDeal,
  isMultiDeal,
  openDealOutboundUrl,
} from '../../../utils/promos';
import DiscountBox from '../../atoms/DiscountBox';
import PromoModalBodyRebatePromotion from '../PromoModal/BodyRebatePromotion';
import { getProductEventDataWithMattressHierarchy } from '../../../utils/dataLayer';
import PromoModalBodyRetailersPromotion from '../PromoModal/BodyRetailersPromotion';
import { ROUTES } from '../../../constants/commons';
import { NewPromotion, PromotionType } from '../../../types/promotion';
import LinkButton from '../../atoms/LinkButton';

export const BEST_DEAL_ID = 'best-deal';
export const BEST_DEAL_BUTTON_TEXT = 'How to Get this Deal';
export const BEST_DEAL_LISTED_TITLE = 'Get the Best Deal for';
export const BEST_DEAL_NOT_LISTED_TITLE = 'Get the Best Deal Near You';
export const BEST_NEAREST_DEAL_BUTTON_TEXT = 'Get Deal';

export type BestDealProps = {
  pageRegion: PageRegion;
  user: User | null;
  deal?: Deal;
  nearestDeals?: boolean;
  onGetDeal(deal: Deal): void;
};

const BestDeal: FC<BestDealProps> = ({ pageRegion, deal, nearestDeals = false, onGetDeal }) => {
  const multiDeal = isMultiDeal(deal);
  const { onlinePromotion, rebatePromotion, generalPromotion } = getPromotionsFromDeal(
    multiDeal ? [] : deal!.deals[0].promotions,
  );
  const isOnlyOnlinePromotion = onlinePromotion && !rebatePromotion;

  const getBestDealTitle = (): JSX.Element => {
    const listed = deal?.brand.listed;
    let title = '';

    if (listed) title = `${BEST_DEAL_LISTED_TITLE} ${deal?.brand.name}`;
    else title = BEST_DEAL_NOT_LISTED_TITLE;

    return <h2 className="best-deal__title">{title}</h2>;
  };

  const goToCashback = (rebate: NewPromotion, dealHash?: string): void => {
    const { protocol, host, pathname } = window.location;

    let url = `${protocol}//${host}${ROUTES.cashbackCode}${rebate.id}/?prev=${pathname}`;
    if (deal?.brand.id) url += `&manufacturer=${deal.brand.id}`;
    if (dealHash) url += `&deal=${dealHash}`;

    window.location.href = url;
  };

  return (
    <div data-testid={BEST_DEAL_ID} className={cls('best-deal', { 'nearest-deals': nearestDeals })}>
      {getBestDealTitle()}
      {multiDeal ? (
        <PromoModalBodyRetailersPromotion
          pageRegion={pageRegion}
          deal={deal}
          onGetDeal={(retailer, rebate, _, dealHash): void => {
            if (rebate && rebate.promotion_type === PromotionType.LOCAL_REBATE) {
              goToCashback(rebate, dealHash);
            }
          }}
        />
      ) : (
        <DiscountBox>
          <LinkButton
            ariaLabel="Get Deal"
            rel="nofollow"
            to={isOnlyOnlinePromotion && onlinePromotion ? onlinePromotion.outbound_url : undefined}
            ctaData={{
              category: CTAClickCategory.CASHBACK_PROMOTION,
              level: CTAClickLevel.SECONDARY,
              url: '/',
              pageRegion,
              product: deal?.brand
                ? getProductEventDataWithMattressHierarchy(deal.brand as Brand)
                : EMPTY_EVENT_PRODUCT,
            }}
            useRouter={false}
            onClick={(): void => {
              const isLocalRebate = getIsLocalRebatePromotionFromSingleDeal(deal);
              const isListedProduct = deal?.brand.listed;

              if (isListedProduct) {
                if (deal && !isLocalRebate) openDealOutboundUrl(deal);
                else if (deal) onGetDeal(deal);
              } else if (!isListedProduct && rebatePromotion) {
                goToCashback(rebatePromotion, deal?.deals[0].hash);
              }
            }}
          >
            <PromoModalBodyRebatePromotion
              pageRegion={pageRegion}
              deal={deal}
              onlinePromotion={onlinePromotion}
              rebatePromotion={rebatePromotion}
              generalPromotion={generalPromotion}
              toggleable={false}
              showReadyToBuy={false}
              notListedBrand={!deal?.brand.listed || deal?.brand.get_status_display === 'Discontinued'}
              skipShortLinks
            />
            <Row className="justify-content-center">
              <div className="discount-box__get-deal">
                {nearestDeals ? BEST_NEAREST_DEAL_BUTTON_TEXT : BEST_DEAL_BUTTON_TEXT}
              </div>
            </Row>
          </LinkButton>
        </DiscountBox>
      )}
    </div>
  );
};

export default BestDeal;
