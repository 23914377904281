import React, { FC } from 'react';
import { createRoot } from 'react-dom/client';
import SUReviewSummary, { REVIEW_SUMMARY_ID } from 'shared-ui/src/components/organism/ReviewSummary';
import { RATINGS } from 'shared-ui/src/constants/review-summary';
import useFetch from 'shared-ui/src/hooks/useFetch';
import { Mattresses, MattressesReviews } from 'shared-ui/src/types/review-summary';
import qs from 'qs';

interface ReviewSummaryProps {
  classname: string;
  slug: string;
}

const ReviewSummary: FC<ReviewSummaryProps> = ({ classname, slug }) => {
  const baseQueryParams = { page_type: RATINGS, slug, classname };

  const summaryDataEndpoint = `/${classname}/${slug}/reviews/${qs.stringify(baseQueryParams, {
    indices: false,
    arrayFormat: 'repeat',
    addQueryPrefix: true,
  })}`;

  const { data: reviewData } = useFetch<MattressesReviews>(summaryDataEndpoint, baseQueryParams);
  const { data: productData } = useFetch<Mattresses>(`/${classname}/${slug}/`);

  if (reviewData && productData)
    return (
      <SUReviewSummary product={productData} summaryData={reviewData.extra_data.reviews_summary} type="profile-page" />
    );

  return (
    <div className="review-summary-placeholder">
      <div className="review-summary-placeholder__top-info">
        <div className="review-summary-placeholder__top-info__box" />
        <div className="review-summary-placeholder__top-info__text">
          <div className="review-summary-placeholder__top-info__text__title" />
          <div className="review-summary-placeholder__top-info__text__description" />
        </div>
      </div>

      <div className="review-summary-placeholder__summary">
        <div className="review-summary-placeholder__summary__item">
          <div className="review-summary-placeholder__summary__item__title" />
          <div className="review-summary-placeholder__summary__item__rating" />
        </div>
        <div className="review-summary-placeholder__summary__item">
          <div className="review-summary-placeholder__summary__item__title" />
          <div className="review-summary-placeholder__summary__item__rating" />
        </div>
        <div className="review-summary-placeholder__summary__item">
          <div className="review-summary-placeholder__summary__item__title" />
          <div className="review-summary-placeholder__summary__item__rating" />
        </div>
        <div className="review-summary-placeholder__summary__item">
          <div className="review-summary-placeholder__summary__item__title" />
          <div className="review-summary-placeholder__summary__item__rating" />
        </div>
        <div className="review-summary-placeholder__summary__item">
          <div className="review-summary-placeholder__summary__item__title" />
          <div className="review-summary-placeholder__summary__item__rating" />
        </div>
        <div className="review-summary-placeholder__summary__item">
          <div className="review-summary-placeholder__summary__item__title" />
          <div className="review-summary-placeholder__summary__item__rating" />
        </div>
        <div className="review-summary-placeholder__summary__item large">
          <div className="review-summary-placeholder__summary__item__title" />
          <div className="review-summary-placeholder__summary__item__rating" />
        </div>
      </div>

      <div className="review-summary-placeholder__ctas">
        <div className="review-summary-placeholder__ctas__button" />
        <div className="review-summary-placeholder__ctas__button" />
      </div>
    </div>
  );
};

export function renderReviewSummary() {
  const element = document.getElementById(REVIEW_SUMMARY_ID);

  if (element) {
    const root = createRoot(element);
    const classname = element.getAttribute('data-classname');
    const slug = element.getAttribute('data-slug');

    if (classname && slug) root.render(<ReviewSummary classname={classname} slug={slug} />);
  }
}

export default ReviewSummary;
