import React from 'react';
import PropTypes from 'prop-types';

const CLASS_MODAL_OPEN = 'modal-open';
class BaseModal extends React.Component {
  constructor(props) {
    super(props);

    this.modal = React.createRef();

    try {
      this.onDismiss = this.onDismiss.bind(this);
    } catch (e) {
      console.error(e);
    }
  }

  componentDidMount() {
    const { body } = document;
    const html = document.documentElement;

    window.addEventListener('keyup', this.handleKeyUp);
    $(this.modal.current).modal();
    html.classList.add(CLASS_MODAL_OPEN);
    document.body.style.top = `-${window.scrollY}px`;
    document.body.style.position = 'fixed';
    document.body.style.width = '100vw';

    // call on dismiss when user click on backdrop to close the modal.
    $(this.modal.current).on('hidden.bs.modal', () => {
      this.onDismiss();
    });

    $(this.modal.current).on('shown.bs.modal', () => {
      if (!body.classList.contains(CLASS_MODAL_OPEN)) {
        body.classList.add(CLASS_MODAL_OPEN);
      }
    });
  }

  handleKeyUp(e) {
    e.preventDefault();

    if (e.keyCode === 27) {
      this.onDismiss();
    }
  }

  onDismiss() {
    const { onHide } = this.props;
    const html = document.documentElement;

    html.classList.remove(CLASS_MODAL_OPEN);

    const scrollY = document.body.style.top;
    document.body.style.position = '';
    document.body.style.width = '';
    window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
    document.body.style.top = '';

    if (onHide) onHide();
  }

  render() {
    const { className, title, bodyTitle, subtitle, header, body, centered, footer } = this.props;

    return (
      <div className={`base-modal ${className} modal fade`} ref={this.modal} role="dialog">
        <div className={`modal-dialog modal-lg ${centered ? 'modal-dialog-centered' : ''}`} role="document">
          <div className="modal-content">
            <div className="modal-header">
              {header || <h5 className="modal-title">{title}</h5>}
              <button
                aria-label="Close"
                type="button"
                className="modal-close-btn"
                onClick={() => {
                  $(this.modal.current).modal('hide');
                }}
              >
                <i className="fa-regular fa-xmark fa-lg close close-backend" />
              </button>
            </div>
            <div className="modal-body">
              {bodyTitle && <div className="body-title">{bodyTitle}</div>}

              {subtitle && <div className="body-subtitle">{subtitle}</div>}

              {body}
            </div>

            {footer && <div className="modal-footer">{footer}</div>}
          </div>
        </div>
      </div>
    );
  }
}

BaseModal.defaultProps = {
  className: null,
  body: null,
  footer: null,
};

BaseModal.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  bodyTitle: PropTypes.string,
  subtitle: PropTypes.string,
  header: PropTypes.element,
  body: PropTypes.element,
  footer: PropTypes.element,
  centered: PropTypes.bool,
  onHide: PropTypes.func,
};

export default BaseModal;
