import React, { memo, useEffect, useRef } from 'react';
import isNumber from 'lodash/isNumber';
import Carousel, { CarouselProps } from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

export interface SliderProps extends CarouselProps {
  currentSlide?: number;
  children: JSX.Element[];
  onBeforeChange?(nextSlide: number): void;
  onAfterChange?(previousSlide: number): void;
}

const Slider: React.FunctionComponent<SliderProps> = ({
  currentSlide,
  responsive,
  arrows = true,
  showDots = false,
  autoPlay = false,
  infinite = false,
  partialVisbile = true,
  children,
  onBeforeChange,
  onAfterChange,
}: SliderProps) => {
  const carousel: any = useRef(null);

  useEffect(() => {
    if (isNumber(currentSlide) && currentSlide! >= 0) {
      carousel.current.goToSlide(currentSlide);
    }
  }, [currentSlide]);

  const handleBeforeChange = (nextSlide: number): void => {
    if (onBeforeChange) onBeforeChange(nextSlide);
  };

  const handleAfterChange = (previousSlide: number): void => {
    if (onAfterChange) onAfterChange(previousSlide);
  };

  return (
    <div>
      <Carousel
        ref={carousel}
        containerClass="slider"
        responsive={responsive}
        arrows={arrows}
        autoPlay={autoPlay}
        showDots={showDots}
        infinite={infinite}
        partialVisible={partialVisbile}
        removeArrowOnDeviceType={['tablet', 'mobile']}
        beforeChange={handleBeforeChange}
        afterChange={handleAfterChange}
      >
        {children}
      </Carousel>
    </div>
  );
};

export default memo(Slider);
