import React, { FC, useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { captureException } from '@sentry/browser';
import SUDealCoupon, { DealCouponLayoutType } from 'shared-ui/src/components/molecules/DealCoupon';
import { isMultiDeal } from 'shared-ui/src/utils/promos';
import { Deal, ProductDealResponse } from 'shared-ui/src/types/deal';
import useFetch from 'shared-ui/src/hooks/useFetch';
import { PageRegion } from 'datalayer-service/src/types/enums';

const BEST_DEALS_SIDEBAR_ID = 'best-deals-sidebar';

const DEALS_ENDPOINT = '/promos/deals-stores/';

interface BestDealsSidebarProps {
  pageRegion: PageRegion;
  brandSlug: string;
  productName: string;
  mobile: boolean;
  onSeeDeal: () => void;
  isCMSPage?: boolean;
}

const BestDealsSidebar: FC<BestDealsSidebarProps> = ({
  pageRegion,
  brandSlug,
  productName,
  mobile,
  onSeeDeal,
  isCMSPage = false,
}) => {
  const { data, error } = useFetch<ProductDealResponse>(`${DEALS_ENDPOINT}/${brandSlug}/`);
  const [formattedDeals, setFormattedDeals] = useState<Deal[] | string[]>([]);
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    if (data && data.deals && data.deals.length > 0) {
      const items: Deal[] = [];

      if (data.brand) {
        if (isMultiDeal(data)) {
          data.deals.forEach((singleDeal) => {
            if (data.brand) items.push({ brand: data.brand, deals: [singleDeal] });
          });
        } else {
          items.push(data as Deal);
        }
      }

      setIsFetching(false);
      setFormattedDeals(isCMSPage ? [items[0]] : items);
    } else setFormattedDeals(['', '', '']);

    if (error) {
      const msg = `Error fetching ${productName} deals`;
      console.error(msg, error);
      captureException(new Error(`${msg} -> ${error}`));
    }
  }, [data, error]);

  return (
    <div className="best-deals-container">
      {!isCMSPage ? <h3 className="best-deals-title">Best Deals on {productName}</h3> : null}
      <ul>
        {formattedDeals.map((deal, idx) => {
          const key = isFetching ? `best-deal-loading-${idx}` : `${deal.brand.slug}-${deal.deals[0].retailer.slug}`;

          return (
            <li key={key}>
              <SUDealCoupon
                pageRegion={pageRegion}
                // @ts-ignore
                user={window.userData}
                deal={isFetching ? null : deal}
                layout={mobile ? DealCouponLayoutType.HORIZONTAL : DealCouponLayoutType.VERTICAL}
                isFetching={isFetching}
                onSeeDeal={onSeeDeal}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default BestDealsSidebar;

export function renderBestDealsSidebar(onSeeDeal) {
  const elements = document.querySelectorAll(`[id=${BEST_DEALS_SIDEBAR_ID}]`);

  elements.forEach((element) => {
    if (element) {
      const pageRegion = element.getAttribute('data-page-region');
      const brandSlug = element.getAttribute('data-brand-slug');
      const productName = element.getAttribute('data-product-name');
      const mobile = element.getAttribute('data-mobile');
      const isCMSPage = element.getAttribute('data-is-cms') === 'true';
      const root = createRoot(element);

      if (brandSlug && productName) {
        root.render(
          <BestDealsSidebar
            pageRegion={(pageRegion as PageRegion) || PageRegion.SHOP_ONLINE}
            brandSlug={brandSlug}
            productName={productName}
            mobile={mobile === 'True'}
            onSeeDeal={onSeeDeal}
            isCMSPage={isCMSPage}
          />,
        );
      }
    }
  });
}
